<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">{{ $t('message.parcel_price_list') }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div v-loading="loadingData" class="timeline-items__right rounded-sm w-100 p-4">
            <template>
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-checkbox v-model="checkAll"> {{ $t('message.select_all') }} </el-checkbox>
                </el-col>
                <el-col :span="4">
                  <el-input size="mini" :placeholder="$t('message.price_without_delivery')" v-model="price_without_delivery" @input="updatePrices" type="number"></el-input>
                </el-col>
                <el-col :span="4">
                  <el-input size="mini" :placeholder="$t('message.price_with_delivery')" v-model="price_with_delivery"  @input="updatedeliveryPrices"  type="number"></el-input>
                </el-col>
              </el-row>
              <hr>
            </template>

            <template>
                <el-table
                :data="partnerList"
                stripe
                style="width: 100%">
                    <el-table-column
                    sortable
                    :sort-method="sortByChecked"
                    :label="$t('message.name')"
                    >
                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.checked">{{ scope.row.name }}</el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="200px"
                        prop="price_without_delivery"
                        :label="$t('message.price_without_delivery')"
                        >
                            <template slot-scope="scope">
                                <el-input size="mini" :placeholder="$t('message.price_without_delivery')" v-model="scope.row.price_without_delivery" type="number"></el-input>
                            </template>
                    </el-table-column>
                    <el-table-column
                        width="200px"
                        prop="price_with_delivery"
                        :label="$t('message.price_with_delivery')"
                        >
                            <template slot-scope="scope">
                                <el-input size="mini" :placeholder="$t('message.price_with_delivery')" v-model="scope.row.price_with_delivery" type="number"></el-input>
                            </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  
  mixins: [form, drawer],
  props: ['partner'],
  data() {
    return {
        partnerList: [],
        price_without_delivery: 0,
        price_with_delivery: 0,
        checkAll: false,
        loadingData: false,
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          model: "directionTariffs/model",
          partners: "partnerTariffPrices/list",
      }),
  },
  watch: {
    checkAll: {
        handler: async function(newVal, oldVal) {
          this.partnerList.forEach(el => this.$set(el, 'checked', newVal));
        },
        deep: true,
        immediate: true
    },
  },
  methods: {
      ...mapActions({
          save: "partnerTariffPrices/store",
          updatePartners: "partnerTariffPrices/directionTariffPartners",
      }),
      updatePrices (val) {
        this.partnerList.forEach(el => {
          if(el.checked){
            this.$set(el, 'price_without_delivery', val)
          }
        });
      },
      updatedeliveryPrices (val) {
        this.partnerList.forEach(el => {
          if(el.checked){
            this.$set(el, 'price_with_delivery', val)
          }
        });
      },
      
      sortByChecked(a, b){
        if(a.checked > b.checked){
          return -1;
        }
      },
      afterLeave() {
        this.partnerList = [];
      },
      afterOpen(){
        this.loadingData = true;
        this.updatePartners({direction_tariff_id: this.model.id}).then(res => {
            this.partnerList = JSON.parse(JSON.stringify(this.partners));
            this.loadingData = false;
        }).catch(err => {this.loadingData = false;})
      },
      submit(close = true) {
          this.form = this.partnerList.map(el => {
            return {
              'id': el.id,
              'client_id': el.client_id,
              'direction_tariff_id': this.model.id,
              'price_without_delivery': el.price_without_delivery,
              'price_with_delivery': el.price_with_delivery,
            }
          })
          this.loadingButton = true;
          this.save(this.form)
              .then((res) => {
                  this.loadingButton = false;
                  this.$alert(res);
                  if (close) this.close();
              })
              .catch((err) => {
                  this.loadingButton = false;
                  this.$alert(err);
              });
      },
  },
};
</script>
<style>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
</style>