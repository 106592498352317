<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.directionTariffs") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.name.show">
                    {{ columns.name.title }}
                </th>
                
                <th v-if="columns.from_filial_id.show">
                    {{ columns.from_filial_id.title }}
                </th>

                <th v-if="columns.to_filial_id.show">
                    {{ columns.to_filial_id.title }}
                </th>

                <th v-if="columns.shipping_type_id.show">
                    {{ columns.shipping_type_id.title }}
                </th>

                <th v-if="columns.average_days.show">
                    {{ columns.average_days.title }}
                </th>

                <th v-if="columns.price_delivery_to_door.show">
                    {{ columns.price_delivery_to_door.title }}
                </th>

				<th v-if="columns.for_systeam.show">
                    {{ columns.for_systeam.title }}
                </th>

				<th v-if="columns.for_site.show">
                    {{ columns.for_site.title }}
                </th>

                <th v-if="columns.comment.show">
                    {{ columns.comment.title }}
                </th>
                
                <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.name.show">
                  <crm-input
                      :placeholder="columns.name.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.name"
                  ></crm-input>
              </th>

              <th v-if="columns.from_filial_id.show">
                  <crm-input
                      :placeholder="columns.from_filial_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.from_filial_id"
                  ></crm-input>
              </th>

              <th v-if="columns.to_filial_id.show">
                  <crm-input
                      :placeholder="columns.to_filial_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.to_filial_id"
                  ></crm-input>
              </th>

              <th v-if="columns.shipping_type_id.show">
                  <crm-input
                      :placeholder="columns.shipping_type_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.shipping_type_id"
                  ></crm-input>
              </th>

              <th v-if="columns.average_days.show">
                  <crm-input
                      :placeholder="columns.average_days.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.average_days"
                  ></crm-input>
              </th>

              <th v-if="columns.price_delivery_to_door.show">
              </th>

              <th v-if="columns.for_systeam.show">
                <el-select 
                    v-model="filterForm.for_systeam" 
                    :placeholder="columns.for_systeam.title" 
                    :class="mode ? 'filter__day' : 'filter__night'"
                    clearable
                    >
                    <el-option :label="$t('message.active')" :value="true"></el-option>
                    <el-option :label="$t('message.inactive')" :value="false"></el-option>
                </el-select>
              </th>

              <th v-if="columns.for_site.show">
                <el-select 
                    v-model="filterForm.for_site" 
                    :placeholder="columns.for_site.title" 
                    :class="mode ? 'filter__day' : 'filter__night'"
                    clearable
                    >
                    <el-option :label="$t('message.active')" :value="true"></el-option>
                    <el-option :label="$t('message.inactive')" :value="false"></el-option>
                </el-select>
              </th>

              <th v-if="columns.comment.show">
              </th>

              <th
                  class="settinW"
                  v-if="columns.settings.show"
              ></th>
          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="directionTariff in list" :key="directionTariff.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ directionTariff.id }}
                </td>

                <td v-if="columns.name.show">
                    {{ directionTariff.name }}
                </td>

                <td v-if="columns.from_filial_id.show">
                    {{ directionTariff.from_filial ? directionTariff.from_filial.name : '' }}
                </td>

                <td v-if="columns.to_filial_id.show">
                    {{ directionTariff.to_filial ? directionTariff.to_filial.name : '' }}
                </td>

                <td v-if="columns.shipping_type_id.show">
                    {{ directionTariff.shipping_type }}
                </td>

                <td v-if="columns.average_days.show">
                    {{ directionTariff.average_days }}
                </td>

                <td v-if="columns.price_delivery_to_door.show">
                    <span v-if="directionTariff.price_delivery_to_door">
                        {{ directionTariff.price_delivery_to_door | formatMoney(2) }} / {{ $t('message.kg') }}
                    </span>
                </td>

				<td v-if="columns.for_systeam.show">
                    {{ directionTariff.for_systeam ? $t('message.active') : $t('message.inactive') }}
                </td>

				<td v-if="columns.for_site.show">
                    {{ directionTariff.for_site ? $t('message.active') : $t('message.inactive') }}
                </td>

                <td v-if="columns.comment.show">
                    {{ directionTariff.comment }}
                </td>

                <td v-if="columns.settings.show" class="settings-td">
                    <crm-settings
                        :name="$options.name"
                        :model="directionTariff"
                        :actions="actions"
                        :permissionShow="'directionTariffs.update'"
                        :permissionDestroy="'directionTariffs.delete'"
                        @edit="edit"
                        @delete="destroy"
                    ></crm-settings>
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerCreate" 
               ref="drawerCreate"
               class="bg-se"
               size="70%"
               @opened="drawerOpened('drawerCreateChild')"
               @closed="drawerClosed('drawerCreateChild')"
               >
                <div>
                    <crm-create ref="drawerCreateChild" drawer="drawerCreate"> </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="70%"
                class="bg-se"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer> 
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions } from "vuex";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";

export default {
    name: "directionTariffs",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        selectFromFilial,
        selectToFilial,
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapGetters({
            list: "directionTariffs/list",
            columns: "directionTariffs/columns",
            pagination: "directionTariffs/pagination",            
            filter: "directionTariffs/filter",
            sort: "directionTariffs/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "directionTariffs/index",
            setPagination: "directionTariffs/setPagination",
            updateSort: "directionTariffs/updateSort",
            updateFilter: "directionTariffs/updateFilter",
            updateColumn: "directionTariffs/updateColumn",
            updatePagination: "directionTariffs/updatePagination",
            show: "directionTariffs/show",
            empty: "directionTariffs/empty",
            delete: "directionTariffs/destroy",
            refreshData: "directionTariffs/refreshData",
        }),
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('directionTariffs/EMPTY_LIST');
        next()
    },
};
</script>

